<template>
    <span v-show="show || (!isPremium && showPremiumMarker)">
        <b-icon :id="uniqueId" ref="icon" icon="star-fill" :class="getClasses()" :style="`color: ${markerColor}`" />
        <b-popover
            v-if="popover"
            id="popover"
            :target="uniqueId"
            triggers="hover"
            placement="top"
            :boundary="boundary"
            :container="container"
        >
            <div class="text-center">
                <slot></slot>
                <b-button class="button-text" variant="success" size="sm" @click="goPremium()">Go Premium!</b-button>
            </div>
        </b-popover>
    </span>
</template>

<script>
import { defineComponent } from 'vue'
import uniqueId from 'lodash.uniqueid'
import StatsigEvent from '../common/StatsigEvent'
import { mapGetters, mapState } from 'vuex'
import Common from '../mixins/Common'

export default defineComponent({
    name: 'PremiumMarker',
    mixins: [Common],
    props: {
        fade: {
            type: Boolean,
            default: false,
        },
        popover: {
            type: Boolean,
            default: true,
        },
        name: {
            type: String,
            default: '',
        },
        page: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: '',
        },
        show: {
            type: Boolean,
            default: false,
        },
        boundary: {
            type: String,
            default: 'scrollParent',
        },
        container: {
            type: String,
            default: 'body',
        },
    },
    data() {
        return {
            uniqueId: null,
            premiumMarkerColor: 'var(--primary)',
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            hasPlan: 'user/hasPlan',
            isWorksheet: 'document/isWorksheet',
        }),
        ...mapState(['document', 'cards', 'user']),
        markerColor() {
            return this.color ? this.color : this.premiumMarkerColor
        },
        showPremiumMarker() {
            if (!this.isLoggedIn) {
                return false
            }

            if (!this.isWorksheet) {
                return this.showPremiumWall
            }

            if (this.document.is_published && this.document.user_id !== this.user.user.id) {
                return true
            }

            return !!(this.user.user.trialed || this.cards.cards.length)
        },
        isPremium() {
            return this.hasPlan && ['Gratis', 'Lifetime', 'Premium', 'Free Trial'].includes(this.user.user.subscription_plan)
        },
    },
    created() {
        this.uniqueId = uniqueId('premium_marker_')
    },
    methods: {
        getClasses() {
            return [this.fade ? 'fade-50' : '']
        },
        goPremium() {
            this.$emit('click')

            if (this.page) {
                this.$store.dispatch('document/setDocument', {
                    entity_type: this.page,
                })
            }

            if (this.isLoggedIn) {
                this.showPremiumPaywall()
                this.$eventApi.create_event('plan_selection_overlay_seen', this.name)
            } else {
                this.$eventApi.create_event('plan_selection_overlay_seen', 'not-logged-in|' + this.name)
                this.$modals.open('logIn')
            }

            this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.GO_PREMIUM,
                value: this.premiumMarkerColor,
            })
        },
    },
})
</script>

<style scoped>
.button-text {
    white-space: nowrap;
    text-align: center;
}
</style>
